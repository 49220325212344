/* eslint-disable max-len */
import Box from '@mui/material/Box';
import React from 'react';

import { useSettings } from '../../hooks';
import { Skeleton, Table } from '../shared';

const MixesTable = (props) => {
  const { data = [], unit, loading, isMobile } = props;

  const { formatDate } = useSettings();

  const getFeedColumns = () => {
    const feedRow = new Set();
    data.forEach((q) => {
      q?.feeds?.forEach((feed) => feedRow.add(feed.feed_name));
    });

    return Array.from(feedRow) || [];
  };

  const getColumns = () => {
    const valuesColumns = [
      {
        title: 'Feed',
        field: 'feed_name',
        sort: true,
        headerStyles: { minWidth: '200px' },
      },
    ];
    data.forEach((el, idx) => {
      valuesColumns.push({
        title: el.FeedName,
        field: `${unit}_${idx}`,
        sort: true,
        formatter: (value) => (value ? value?.toFixed(2) + '%' : '---'),
        headerFormatter: () => (
          <Box component='div' sx={{ minWidth: '250px' }}>
            <Box component='div'>{el.FarmName || el.farmName}</Box>
            <Box component='div'>{el.FeedName}</Box>
            <Box component='div'>{formatDate(el.OADate, true)}</Box>
          </Box>
        ),
      });
    });
    return valuesColumns;
  };

  const getRows = () => {
    const newData = [];
    const feedColumns = getFeedColumns();

    feedColumns.forEach((feedCol) => {
      const feedOBj = { feed_name: feedCol };
      data.forEach((q, index) => {
        const feedInfo = q?.feeds?.find((feed) => feed.feed_name === feedCol);
        if (feedInfo) {
          feedOBj[`DM_%mix_${index}`] = feedInfo['DM_%mix'];
          feedOBj[`AF_%mix_${index}`] = feedInfo['AF_%mix'];
        }
      });
      newData.push(feedOBj);
    });

    return newData;
  };

  if (loading) {
    return <Skeleton rows={8} />;
  }

  if (!data || !data?.length) {
    return null;
  }

  const columns = getColumns();
  const rows = getRows();

  return (
    <Table
      columns={columns}
      keyField='feed_name'
      rows={rows}
      stickyColumn={!isMobile}
      stickyHeader
      sx={{ maxHeight: { md: 'calc(100vh - 340px)', xs: 'calc(100vh - 140px)' } }}
      toolbar
    />
  );
};

export default MixesTable;
