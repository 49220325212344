import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MuiInput from '@mui/material/OutlinedInput';
import PropTypes from 'prop-types';
import React from 'react';

const Input = (props) => {
  const {
    value = '',
    onChange = () => {},
    label = '',
    placeholder = '',
    id = '',
    name = '',
    disabled = false,
    multiline = false,
    type = 'text',
    startAdornment = null,
    endAdornment = null,
    helperText = '',
    maxLength,
    error = false,
    size,
    sx = {},
  } = props;

  return (
    <FormControl sx={{ minWidth: 120, ...sx }} size={size}>
      <InputLabel id={`${id}-helper-label`}>{label}</InputLabel>
      <MuiInput
        disabled={disabled}
        endAdornment={endAdornment && <InputAdornment position='end'>{endAdornment}</InputAdornment>}
        error={error}
        id={`input_${id}`}
        inputProps={{ maxLength: maxLength || 'auto' }}
        label={label}
        multiline={multiline}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        startAdornment={startAdornment && <InputAdornment position='start'>{startAdornment}</InputAdornment>}
        sx={{ backgroundColor: '#ffffff' }}
        type={type}
        value={value}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default Input;
