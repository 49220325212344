/* eslint-disable max-len */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RationIcon from '@mui/icons-material/AssignmentOutlined';
import ChartIcon from '@mui/icons-material/InsertChartOutlined';
import PrintIcon from '@mui/icons-material/Print';
import TableIcon from '@mui/icons-material/TableRowsOutlined';
import TreeIcon from '@mui/icons-material/TocOutlined';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { filterCurrentComparisons, getRationDataById } from '../../../actions/comparisonsActions';
import { setUserFarms } from '../../../actions/farmsActions';
import { getTemplateList } from '../../../actions/rationsActions';
import { ComparisonsGraphs, ComparisonsTree, ComparisonsTable, OutputsTable } from '../../../components/comparisons';
import LoadScreen from '../../../components/load-screen';
import { TemplateSelect } from '../../../components/rations';
import { SideMenu, BottomNavigation, Toggle, SearchInput, Skeleton } from '../../../components/shared';
import NoResults from '../../../components/shared/no-results';
import { Tabs, TabPanel } from '../../../components/shared/tabs';
import { nutrientLabels } from '../../../constants';
import { comparisonsUnits } from '../../../constants/enums';
import { nutrientOrder } from '../../../constants/nutrientLabels';
import { useLoading } from '../../../hooks';
import { rationsSelector, farmsSelector, comparisonsSelector } from '../../../reducers/selectors';
import { containerPadding } from '../../../styles/theme/shared';

const tabs = {
  RATIONS: 0,
  OUTPUTS: 1,
  GRAPHS: 2,
  TREE: 3,
};

const CompareRationsPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const rations = useSelector(rationsSelector);
  const farms = useSelector(farmsSelector);
  const comparisons = useSelector(comparisonsSelector);

  const sortNutrientsByDefault = () => {
    return Object.keys(nutrientLabels)
      ?.sort((a, b) => {
        const indexA = nutrientOrder.indexOf(a);
        const indexB = nutrientOrder.indexOf(b);
        if (indexA === -1) return 1; // Move 'a' to the end if not found in nutrientOrder
        if (indexB === -1) return -1; // Move 'b' to the end if not found in nutrientOrder
        return indexA - indexB;
      })
      ?.map((key) => nutrientLabels[key]);
  };

  const { templateList, templateListLoaded } = rations;

  const { pickedFarms, farmsList, exactFarm, isLoaded } = farms;

  const { currentComparisons } = comparisons;

  const [currentTemplate, setCurrentTemplate] = useState(undefined);
  const [collapsed, setCollapse] = useState(true);
  // const [collapsed, setCollapse] = useState(!exactFarm.length && !currentComparisons?.length);

  const [currentPage, setCurrentPage] = useState(!exactFarm.length ? tabs.TREE : tabs.RATIONS);
  const [activeTab, setActiveTab] = useState(tabs.RATIONS);

  const [unit, setUnit] = useState(comparisonsUnits.DRY_MATTER);

  const [selected, setSelected] = useState([]);
  const [nutrients, setNutrients] = useState(sortNutrientsByDefault());
  const [search, setSearch] = useState('');
  const [fetching, setFetching] = useState(false);

  const { loading, startLoading, stopLoading } = useLoading(true);

  const onLoad = async () => {
    try {
      startLoading();
      await dispatch(getTemplateList());
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  const fetchRationInfo = async (data) => {
    const rationObj = {
      id: data.id,
      Farm_ID: data.farmId,
      FarmName: data.farmName,
      LocationName: data.locName,
      CattleName: data.cattleName,
      RecipeName: data.recipe,
      OADate: data.implemented_oadate,
    };
    await dispatch(getRationDataById(rationObj));
  };

  const handleRationClick = async (data) => {
    if (currentComparisons.find((i) => i.id === data.id)) {
      dispatch(filterCurrentComparisons(currentComparisons.filter((i) => i.id !== data.id)));
    } else {
      try {
        setFetching(true);
        await fetchRationInfo(data);
      } catch (err) {
        console.error(err);
      } finally {
        setFetching(false);
      }
    }
  };

  const handleTemplateChange = (e) => {
    const newTemplate = templateList.find((el) => el.template_name === e.target.value);
    setCurrentTemplate(newTemplate);
    setSelected([]);
    setNutrients(newTemplate ? newTemplate.ration_outputs?.map((n) => n.Output) : sortNutrientsByDefault());
  };

  const onSelect = (value) => {
    if (!selected.includes(value)) {
      setSelected([...selected, value]);
    } else {
      setSelected(selected.filter((i) => i !== value));
    }
  };

  const onClear = () => {
    dispatch(filterCurrentComparisons([]));
    setActiveTab(tabs.RATIONS);
    setSelected([]);
  };

  const onFarmsSelect = async (farms) => {
    dispatch(setUserFarms(farms));
  };

  const currentFarmsList = exactFarm.length > 0 ? exactFarm : pickedFarms.length > 0 ? pickedFarms : farmsList;

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const getMobileStyles = (pg) => ({
    display: currentPage === pg ? 'block' : 'none',
    p: { sm: '8px 16px 48px', xs: '8px 0 48px' },
  });

  const navigationTabs = [
    { value: tabs.TREE, label: t('comparisons.list'), icon: <TreeIcon /> },
    {
      value: tabs.RATIONS,
      label: t('comparisons.rations'),
      icon: <RationIcon />,
      disabled: !currentComparisons.length,
    },
    { value: tabs.OUTPUTS, label: t('comparisons.outputs'), icon: <TableIcon />, disabled: !currentComparisons.length },
    { value: tabs.GRAPHS, label: t('comparisons.graphs'), icon: <ChartIcon />, disabled: !selected.length },
  ];

  const nutrientList = React.useMemo(
    () => nutrients.filter((n) => n?.toLowerCase()?.includes(search?.toLowerCase())),
    [nutrients, search]
  );

  return (
    <>
      <Container maxWidth={false} sx={{ p: { lg: 0, md: 0, sm: 0, sx: 0 }, position: 'relative' }}>
        {((loading && !templateListLoaded) || (mobileView && fetching)) && <LoadScreen />}
        <div className='comparisons-page'>
          {mobileView ? (
            <>
              <Box component='div' sx={getMobileStyles(tabs.TREE)}>
                <Box component='div' sx={{ pb: 2 }}>
                  <ComparisonsTree
                    currentComparisons={currentComparisons}
                    handleClick={handleRationClick}
                    onClear={onClear}
                    pickedFarms={pickedFarms}
                    onFarmsSelect={onFarmsSelect}
                    farmsList={farmsList}
                  />
                  {isLoaded && !farmsList?.length && <NoResults text={t('rations.noResults')} />}
                </Box>
              </Box>

              <Box component='div' sx={getMobileStyles(tabs.RATIONS)}>
                {!loading && (
                  <>
                    <Box
                      component='div'
                      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
                    >
                      <h2>{t('comparisons.rations')}</h2>
                      <Toggle
                        handleChange={setUnit}
                        options={[
                          { label: t('asFed'), value: comparisonsUnits.AS_FED },
                          { label: t('dryMatter'), value: comparisonsUnits.DRY_MATTER },
                        ]}
                        value={unit}
                      />
                    </Box>
                    <Box component='div' sx={{ margin: '16px 0' }}>
                      {/*   <FeedList data={currentComparisons} unit={unit} /> */}
                      <ComparisonsTable data={currentComparisons} isMobile={mobileView} unit={unit} />
                    </Box>
                  </>
                )}
              </Box>

              <Box component='div' sx={getMobileStyles(tabs.OUTPUTS)}>
                {!loading && (
                  <>
                    <Box component='div'>
                      <h2>{t('comparisons.outputs')}</h2>
                    </Box>
                    <TemplateSelect
                      currentTemplate={currentTemplate}
                      list={templateList}
                      onChange={handleTemplateChange}
                      size='small'
                    />
                    <SearchInput
                      handleClear={() => setSearch('')}
                      onChange={(e) => setSearch(e.target.value)}
                      sx={{ width: '100%', mt: 2 }}
                      value={search}
                      size='small'
                    />
                    <Box component='div' sx={{ margin: '16px 0' }}>
                      <OutputsTable
                        data={currentComparisons}
                        isMobile={mobileView}
                        loading={fetching}
                        nutrients={nutrientList}
                        onSelect={onSelect}
                        selected={selected}
                      />
                    </Box>
                  </>
                )}
              </Box>

              <Box component='div' sx={getMobileStyles(tabs.GRAPHS)}>
                {!loading && (
                  <>
                    <h2>{t('comparisons.graphs')}</h2>
                    <Box component='div' sx={{ margin: '16px 0' }}>
                      <ComparisonsGraphs currentComparisons={currentComparisons} selected={selected} />
                    </Box>
                  </>
                )}
              </Box>

              <BottomNavigation
                activeTab={currentPage}
                onChange={(event, newValue) => {
                  setCurrentPage(newValue);
                }}
                tabs={navigationTabs}
              />
            </>
          ) : (
            <>
              {!!currentFarmsList.length && (
                <SideMenu collapsed={collapsed} setCollapse={setCollapse}>
                  <ComparisonsTree
                    currentComparisons={currentComparisons}
                    handleClick={handleRationClick}
                    onClear={onClear}
                    pickedFarms={pickedFarms}
                    farmsList={farmsList}
                    onFarmsSelect={onFarmsSelect}
                  />
                </SideMenu>
              )}

              <Container maxWidth={false} sx={{ p: containerPadding }}>
                <Box component='div' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <h2>{t('comparisons.rationsTitle')}</h2>
                  {!!currentComparisons?.length && activeTab === tabs.RATIONS && (
                    <Toggle
                      handleChange={setUnit}
                      options={[
                        { label: t('asFed'), value: comparisonsUnits.AS_FED },
                        { label: t('dryMatter'), value: comparisonsUnits.DRY_MATTER },
                      ]}
                      value={unit}
                    />
                  )}
                  {activeTab === tabs.OUTPUTS && (
                    <Box component='div' sx={{ display: 'flex', alignItems: 'center' }}>
                      <SearchInput
                        handleClear={() => setSearch('')}
                        onChange={(e) => setSearch(e.target.value)}
                        sx={{ width: 250, mr: 2 }}
                        value={search}
                        size='small'
                      />
                      <TemplateSelect
                        currentTemplate={currentTemplate}
                        list={templateList}
                        onChange={handleTemplateChange}
                        size='small'
                      />
                    </Box>
                  )}
                </Box>

                {!currentComparisons?.length && !collapsed && (
                  <Box component='div' sx={{ width: 'fit-content' }}>
                    <MuiAlert icon={<ArrowBackIcon />} severity='warning' variant='outlined'>
                      {t('comparisons.warningAlert')}
                    </MuiAlert>
                  </Box>
                )}
                {isLoaded && !farmsList?.length && <NoResults text={t('rations.noResults')} />}
                {!!currentComparisons?.length && (
                  <Tabs
                    activeTab={activeTab}
                    onChange={setActiveTab}
                    tabs={[
                      { label: t('comparisons.rations'), value: tabs.RATIONS, icon: <RationIcon /> },
                      { label: t('comparisons.outputs'), value: tabs.OUTPUTS, icon: <TableIcon /> },
                      {
                        label: t('comparisons.graphs'),
                        value: tabs.GRAPHS,
                        icon: <ChartIcon />,
                        disabled: !selected.length,
                      },
                    ]}
                  />
                )}

                <TabPanel index={tabs.RATIONS} value={activeTab}>
                  {fetching ? (
                    <Skeleton rows={8} />
                  ) : (
                    <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      {currentComparisons?.length ? (
                        <>
                          <Box component='div' sx={{ flex: '1', maxWidth: '100%' }}>
                            <ComparisonsTable data={currentComparisons} unit={unit} />
                          </Box>
                        </>
                      ) : (
                        <Box component='div' />
                      )}
                    </Box>
                  )}
                </TabPanel>

                <TabPanel index={tabs.OUTPUTS} value={activeTab}>
                  <OutputsTable
                    data={currentComparisons}
                    loading={fetching}
                    nutrients={nutrientList}
                    onSelect={onSelect}
                    selected={selected}
                  />
                </TabPanel>

                <TabPanel index={tabs.GRAPHS} value={activeTab}>
                  <ComparisonsGraphs currentComparisons={currentComparisons} loading={fetching} selected={selected} />
                </TabPanel>
                {selected?.length > 0 && activeTab === tabs.GRAPHS && (
                  <Fab
                    aria-label='add'
                    color='primary'
                    onClick={() => window.print()}
                    sx={{
                      margin: 0,
                      top: 'auto',
                      right: 20,
                      bottom: 20,
                      left: 'auto',
                      position: 'fixed',
                      fontWeight: 'bold',
                    }}
                    variant='extended'
                  >
                    <PrintIcon sx={{ mr: 1 }} />
                    {t('print')}
                  </Fab>
                )}
              </Container>
            </>
          )}
        </div>
      </Container>
      <div className='print-charts'>
        <ComparisonsGraphs
          currentComparisons={currentComparisons}
          customKey='print'
          loading={fetching}
          print
          selected={selected}
        />
      </div>
    </>
  );
};

export default CompareRationsPage;
