import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { getAllFarms } from '../../actions/farmsActions';
import { getUserSettings } from '../../actions/userActions';
import AppBar from '../../components/app-bar';
import LoadScreen from '../../components/load-screen';
import Sidebar from '../../components/sidebar';
import { routes, roles } from '../../constants';
import isAccessPermitted from '../../helpers/isAccessPermitted';
import { useLoading } from '../../hooks';
import { userSelector } from '../../reducers/selectors';

import ActivityLogPage from './activity-log';
import AdminFarmVisit from './admin-farm-visit';
import { BCSPage, BCSNewScorePage, BCSResultsPage } from './body-condition-score';
import { CompareRationsPage, CompareFeedsPage } from './comparisons';
import DashboardPage from './dashboard';
import { FarmVisitPage, FarmVisitNewPage, FarmVisitResultsPage } from './farm-visit';
import FarmsPage from './farms';
import FarmsAccessPage from './farms-access';
import FeedsPage from './feeds';
import MixesPage from './mixes';
import { PSCPage, FeedHistoryPage, TMRHistoryPage } from './particle-size-calculator';
import RationsPage from './rations';
import SettingsPage from './settings';

const drawerWidth = 280;
const drawerWidthMinimized = 56;

const { ADMIN, SUBSCRIBER } = roles.userRoles;

const AppPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useLoading(false);

  const [open, setOpen] = useState(window?.screen?.width >= theme?.breakpoints?.values?.md);

  const currentUser = useSelector(userSelector);

  const onLoad = async () => {
    startLoading();

    try {
      await dispatch(getUserSettings());
      await dispatch(getAllFarms());
      stopLoading();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const mobileView = useMediaQuery((t) => t.breakpoints.down('md'));

  return (
    <Container maxWidth={false} sx={{ p: { md: 0, sm: 0, xs: 0 } }}>
      {loading && <LoadScreen />}
      <CssBaseline />
      <AppBar
        currentUser={currentUser}
        drawerWidth={drawerWidth}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        toggleDrawer={toggleDrawer}
      />
      <Box component='div' sx={{ display: 'flex' }}>
        <Sidebar drawerWidth={drawerWidth} handleDrawerClose={handleDrawerClose} open={open} theme={theme} />
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: 'background.default',
            p: { md: '16px 0', sm: '8px 0', xs: '8px 0 16px' },
            backgroundColor: theme.palette?.primary?.greyLight,
            maxWidth: !mobileView ? `calc(100% - ${open ? drawerWidth : drawerWidthMinimized}px)` : '100%',
          }}
        >
          <Toolbar />
          <Routes>
            <Route element={<DashboardPage />} exact path={routes.HOME} />

            {isAccessPermitted({ roles: [ADMIN], userRoles: currentUser?.roles }) && (
              <>
                <Route element={<DashboardPage />} exact path={routes.DASHBOARD} />
                <Route element={<ActivityLogPage />} exact path={routes.ACTIVITY_LOG} />
                <Route element={<FarmsAccessPage />} exact path={routes.FARM_ACCESS} />
                <Route element={<AdminFarmVisit />} exact path={routes.ADMIN_FARM_VISIT} />
              </>
            )}

            {isAccessPermitted({ roles: [SUBSCRIBER], userRoles: currentUser?.roles }) && (
              <>
                <Route element={<FarmsPage />} exact path={routes.FARMS} />
                <Route element={<RationsPage />} exact path={routes.RATIONS} />
                <Route element={<MixesPage />} exact path={routes.MIXES} />
                <Route element={<FeedsPage />} exact path={routes.FEEDS} />
                <Route element={<CompareRationsPage />} exact path={routes.COMPARE_RATIONS} />
                <Route element={<CompareFeedsPage />} exact path={routes.COMPARE_FEEDS} />

                <Route path={routes.BCS}>
                  <Route element={<BCSPage />} path={routes.BCS} />
                  <Route element={<BCSNewScorePage />} path={routes.BCS_NEW_SCORE} />
                  <Route element={<BCSResultsPage />} path={routes.BCS_RESULTS} />
                  <Route element={<Navigate replace to={routes.BCS} />} path='*' />
                </Route>

                <Route path={routes.PSC}>
                  <Route element={<PSCPage />} path={routes.PSC} />
                  <Route element={<FeedHistoryPage />} path={routes.PSC_FEED_HISTORY} />
                  <Route element={<TMRHistoryPage />} path={routes.PSC_TMR_HISTORY} />
                  <Route element={<Navigate replace to={routes.PSC} />} path='*' />
                </Route>

                <Route path={routes.FARM_VISIT}>
                  <Route element={<FarmVisitPage />} path={routes.FARM_VISIT} />
                  <Route element={<FarmVisitNewPage />} path={routes.FARM_VISIT_NEW} />
                  <Route element={<FarmVisitResultsPage />} path={routes.FARM_VISIT_RESULTS} />
                  <Route element={<Navigate replace to={routes.FARM_VISIT} />} path='*' />
                </Route>
              </>
            )}

            {isAccessPermitted({ roles: [ADMIN, SUBSCRIBER], userRoles: currentUser?.roles }) && (
              <>
                <Route element={<SettingsPage />} exact path={routes.SETTINGS} />
              </>
            )}

            <Route element={<Navigate replace to={routes.HOME} />} path='*' />
          </Routes>
        </Box>
      </Box>
    </Container>
  );
};

export default AppPage;
