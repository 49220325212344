import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';

import { StyledChip } from './styled';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const SiloAutocomplete = (props) => {
  const {
    multiple = true,
    options = [],
    disableCloseOnSelect = true,
    noOptionsText = 'No options',
    onChange = () => {},
    getOptionLabel = () => {},
    defaultValue = [],
    style = {},
    labelKey,
    inputLabel,
    inputPlaceholder,
    value,
    valueKey,
    size,
    mb = 3,
    sx,
  } = props;

  const id = `${props.id || Math.random()}`;

  return (
    <FormControl sx={{ mb: mb, width: '100%', ...sx }}>
      <Autocomplete
        defaultValue={defaultValue}
        disableCloseOnSelect={disableCloseOnSelect}
        getOptionLabel={getOptionLabel}
        id={id?.toString()}
        multiple={multiple}
        noOptionsText={noOptionsText}
        onChange={onChange}
        options={options}
        renderInput={(params) => <TextField {...params} label={inputLabel} placeholder={inputPlaceholder} />}
        renderOption={(renderProps, option, { selected }) => (
          <li {...renderProps} key={option[valueKey]}>
            <Checkbox checked={selected} checkedIcon={checkedIcon} icon={icon} style={{ marginRight: 8 }} />
            {option[labelKey]}
          </li>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue?.map((option, index) => (
            <StyledChip key={`chip_${index}`} {...getTagProps({ index })} label={option[labelKey]} />
          ))
        }
        style={style}
        sx={{ backgroundColor: '#ffffff' }}
        value={value}
        size={size}
      />
    </FormControl>
  );
};

SiloAutocomplete.propTypes = {
  multiple: PropTypes.bool,
  id: PropTypes.any,
  options: PropTypes.array,
  disableCloseOnSelect: PropTypes.bool,
  noOptionsText: PropTypes.string,
  onChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  defaultValue: PropTypes.array,
  style: PropTypes.object,
  labelKey: PropTypes.string,
  inputLabel: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  value: PropTypes.any,
  valueKey: PropTypes.string,
};

export default SiloAutocomplete;
