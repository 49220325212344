import FarmIcon from '@mui/icons-material/AgricultureOutlined';
import RecipeIcon from '@mui/icons-material/AssignmentOutlined';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ArrowUp from '@mui/icons-material/KeyboardArrowUp';
import ClearIcon from '@mui/icons-material/PlaylistRemove';
import TuneIcon from '@mui/icons-material/Tune';
import MixIcon from '@mui/icons-material/ViewListOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { endOfDay, isAfter, isBefore, startOfDay, subYears } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fromOADate } from '../../helpers';
import { useSettings } from '../../hooks';
import { DatePicker, SearchInput } from '../shared';
import Autocomplete from '../shared/autocomplete';
import Tree from '../shared/tree';

const MixesTree = (props) => {
  const { mixList = [], handleClick, mobileView, currentMixes, onClear } = props;

  const { t } = useTranslation();

  const { formatDate } = useSettings();

  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const [selectedFarms, setSelectedFarms] = useState([]);
  const [selectedMixes, setSelectedMixes] = useState([]);

  const [startDate, setStartDate] = useState(subYears(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());

  const farmsList = useMemo(() => {
    const res = [];
    mixList?.forEach((farm) => {
      if (!res.find((item) => item.farm_id === farm.farm_id)) {
        res.push({ farm_id: farm.farm_id, farm_name: farm.farm_name });
      }
    });
    return res;
  }, [mixList]);

  const filteredList = useMemo(() => {
    let result = [...mixList];
    if (selectedFarms?.length) {
      result = result.filter((farm) => !!selectedFarms?.find((item) => item.farm_id === farm.farm_id));
    }
    if (search) {
      result = result.filter((farm) => !search || farm.farm_name?.toLowerCase()?.includes(search?.toLowerCase()));
    }
    return result;
  }, [mixList, search, selectedFarms]);

  const mixesListOptions = useMemo(() => {
    let result = [];

    if (filteredList?.length) {
      filteredList?.forEach((farm) => {
        farm?.feeds?.forEach((feed) => {
          if (!result?.find((item) => item.feed_name === feed.feed_name)) {
            result?.push(feed);
          }
        });
      });
    }

    return result;
  }, [filteredList, search]);

  const treeData = useMemo(
    () =>
      filteredList.map((farm) => ({
        id: farm.farm_id,
        label: (
          <Box component='div'>
            <FarmIcon fontSize='small' /> {farm.farm_name}
          </Box>
        ),
        children: farm.feeds
          ?.filter((feed) => {
            return (
              (!selectedMixes?.length || selectedMixes?.find((f) => f.feed_name === feed.feed_name)) &&
              feed.OADates?.some((oadate) => {
                const date = fromOADate(oadate.oadate);
                return isAfter(date, startOfDay(startDate)) && isBefore(date, endOfDay(endDate));
              })
            );
          })
          .map((feed) => ({
            id: `${farm.farm_id}${feed.feed_name}`,
            label: (
              <Box component='div'>
                <MixIcon fontSize='small' /> {feed.feed_name}
              </Box>
            ),
            children: feed.OADates?.filter((oadate) => {
              const date = fromOADate(oadate.oadate);
              return isAfter(date, startOfDay(startDate)) && isBefore(date, endOfDay(endDate));
            }).map((date) => ({
              id: `${farm.farm_id}${date.old_feedName}${date.oadate}`,
              selected: currentMixes?.find((q) => q.OADate === date.oadate && q.Feed_ID === feed.feed_id),
              name: formatDate(date.oadate, true),
              onClick: () =>
                handleClick({
                  Farm_ID: farm.farm_id,
                  FarmName: farm.farm_name,
                  FeedName: feed.feed_name,
                  Feed_ID: feed.feed_id,
                  OADate: date.oadate,
                }),
              label: (
                <Box component='div'>
                  <RecipeIcon fontSize='small' />
                  <span style={{ display: 'flex' }}>{formatDate(date.oadate, true, true)}</span>
                </Box>
              ),
            })),
          })),
      })),
    [mixList, currentMixes, filteredList, selectedMixes, startDate, endDate]
  );

  return (
    <Box component='div' sx={{ p: { md: 2, sm: 0, xs: 0 } }}>
      <Box
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 42,
        }}
      >
        <Typography
          component='h6'
          sx={{
            margin: '8px 0 0',
            fontWeight: 'bold',
          }}
          variant='h6'
        >
          {t('mixes.selectMix')}
        </Typography>
        {currentMixes && !!currentMixes?.length && (
          <Box component='div' sx={{ mt: 1, ml: 1 }}>
            <Tooltip title={t('clearAll')}>
              <IconButton color='primary' onClick={onClear} size='small'>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          mt: 2,
          p: 1,
          background: '#ffffff',
          borderRadius: 1,
          border: (theme) => `1px solid ${theme?.palette?.primary?.grey}`,
          minWidth: {
            md: 510,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TuneIcon color='secondary' />
            <Typography sx={{ ml: 1 }}>Filters</Typography>
          </Box>
          <Box sx={{ flex: 1, ml: 2, mr: 2 }}>
            <SearchInput
              handleClear={() => setSearch('')}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ width: '100%' }}
              value={search}
              size='small'
              placeholder='Search by farm name'
            />
          </Box>
          <IconButton onClick={() => setOpen(!open)} size='small'>
            {open ? <ArrowUp /> : <ArrowDown />}
          </IconButton>
        </Box>
        {open && (
          <>
            <Box sx={{ mt: 2, maxWidth: 600 }}>
              <Autocomplete
                getOptionLabel={(option) => option.farm_name}
                inputLabel={t('farms.label')}
                inputPlaceholder={t('farms.placeholder')}
                isOptionEqualToValue={(option, value) => option.farm_id === value.farm_id}
                labelKey='farm_name'
                noOptionsText={t('farms.noOptions')}
                onChange={(e, values) => {
                  setSelectedFarms(values);
                  setSelectedMixes([]);
                }}
                options={farmsList}
                value={selectedFarms}
                valueKey='farm_id'
                size='small'
                mb={0}
              />
            </Box>
            <Box sx={{ mt: 2, maxWidth: 600 }}>
              <Autocomplete
                getOptionLabel={(option) => option.feed_name}
                inputLabel='Mixes'
                inputPlaceholder='Select mixes'
                isOptionEqualToValue={(option, value) => option.feed_name === value.feed_name}
                labelKey='feed_name'
                noOptionsText='No Options'
                onChange={(e, values) => setSelectedMixes(values)}
                options={mixesListOptions}
                value={selectedMixes}
                valueKey='feed_name'
                size='small'
                mb={0}
              />
            </Box>
            <Box sx={{ mt: 2, display: 'flex' }}>
              <Box component='div' sx={{ mr: 1, flex: 1 }}>
                <DatePicker label='From' maxDate={endDate} onChange={setStartDate} value={startDate} size='small' />
              </Box>
              <Box component='div' sx={{ ml: 1, flex: 1 }}>
                <DatePicker
                  disableFuture
                  label='To'
                  minDate={startDate}
                  onChange={setEndDate}
                  value={endDate}
                  size='small'
                />
              </Box>
            </Box>
          </>
        )}
      </Box>

      <Box component='div' sx={{ mt: 2 }}>
        <Tree data={treeData} mobileView={mobileView} />
      </Box>
    </Box>
  );
};

export default MixesTree;
