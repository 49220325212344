import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CheckboxRow from './CheckboxRow';
import Row from './Row';

export default function FarmsAccessTable(props) {
  const { users = [], farms = [], changes = [], handleChange, handleSelectAll, handleChangeDate } = props;

  const { t } = useTranslation();

  if (!farms || !farms?.length) {
    return null;
  }

  const columns = [
    { title: t('farmsAccess.farm'), field: 'farm_name' },
    ...users.map((user) => ({ title: user.email, field: 'farm_name' })),
  ];

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: {
          md: 'auto',
          sm: `calc(${window?.screen?.width}px - 48px)`,
        },
        maxHeight: '75vh',
      }}
    >
      <Table aria-label='collapsible table' sx={{ overflow: 'auto' }} stickyHeader>
        <TableHead sx={{ backgroundColor: (theme) => theme?.palette?.primary?.main }}>
          <TableRow sx={{ position: 'sticky', zIndex: 100 }}>
            {columns?.map((column, index) => (
              <TableCell
                key={`${column.field}_${column.title}`}
                sx={{
                  color: '#ffffff',
                  width: index === 0 ? 50 : 'auto',
                  cursor: column.sort ? 'pointer' : 'default',
                  backgroundColor: (theme) => theme?.palette?.primary?.main,
                  ...column?.headerStyles,
                  position: index === 0 ? 'sticky' : 'default',
                  outline: index === 0 ? '1px solid #e0e0e0' : 'none',
                  zIndex: index === 0 ? 2 : 'auto',
                  left: 0,
                  paddingLeft: index === 0 ? '56px' : '',
                  minWidth: index === 0 ? '250px' : 'unset',
                }}
              >
                <Box
                  component='div'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {column.title}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <CheckboxRow
            changes={changes}
            farms={farms}
            handleChange={handleChange}
            handleSelectAll={handleSelectAll}
            users={users}
          />
          {farms?.map((row, index) => (
            <Row
              changes={changes}
              handleChange={handleChange}
              handleChangeDate={handleChangeDate}
              index={index}
              key={row.farm_id}
              row={row}
              users={users}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
