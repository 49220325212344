import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { sortBy } from 'lodash';
import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { farmVisitFieldTypes } from '../../constants/enums';
import { useSettings } from '../../hooks';
import { Button, Input, Score, CheckboxGroup, RadioGroup, Select, Skeleton } from '../shared';
import { ConfirmationModal } from '../shared/modal';

const { TEXT, BOOLEAN, SCORE, CHECKBOX, RADIO } = farmVisitFieldTypes;

const FarmVisitForm = (props) => {
  const { formData, onSubmit, farmsList = [], loading, fetching, selectFarm } = props;

  const { t } = useTranslation();
  const { formatDate } = useSettings();

  const [isModalOpen, toggleModal] = useState(false);

  const onSubmitClick = () => {
    toggleModal(true);
  };

  const form = useFormik({
    initialValues: {
      farm: '',
      date: new Date(),
    },
    onSubmit: onSubmitClick,
  });

  const handleSubmit = async () => {
    await onSubmit(form.values);
    toggleModal(false);
  };

  const renderFormField = (questionId, type, options = []) => {
    switch (type) {
      case TEXT:
        return (
          <Input
            id={questionId}
            name={questionId}
            onChange={form.handleChange}
            sx={{ minWidth: { xs: '100%', sm: '100%', md: '50%' } }}
            value={form.values[questionId]}
          />
        );
      case BOOLEAN:
        return (
          <RadioGroup
            name={questionId}
            onChange={form.handleChange}
            options={[
              { label: t('yes'), value: true },
              { label: t('no'), value: false },
            ]}
            value={form.values[questionId] || null}
          />
        );
      case SCORE:
        return <Score onChange={(value) => form.setFieldValue(questionId, value)} value={form.values[questionId]} />;
      case CHECKBOX:
        return (
          <CheckboxGroup
            name={questionId}
            onChange={(e) => {
              let temp = form.values[questionId] ? [...form.values[questionId]] : [];
              const value = e.target.name;
              const { checked } = e.target;
              if (checked) {
                temp?.push(value);
              } else {
                temp = temp?.filter((v) => v !== value);
              }
              form.setFieldValue(questionId, temp);
            }}
            options={options?.map((option) => ({
              label: option?.Label,
              value: option?.Value,
            }))}
            selected={form.values[questionId] || []}
          />
        );
      case RADIO:
        return (
          <RadioGroup
            name={questionId}
            onChange={form.handleChange}
            options={options?.map((option) => ({
              label: option?.Label,
              value: option?.Value,
            }))}
            value={form.values[questionId] || null}
          />
        );
      default:
        return null;
    }
  };

  const isSectionComplete = useCallback(
    (sectionId) => {
      const section = formData?.FarmVisitSections.sectionsons?.find((s) => s.id === sectionId);
      if (section) {
        const questions = [];
        section?.FarmVisitQuestions?.forEach((question) => {
          if (question?.Required) {
            questions?.push(question.id);
          }
        });

        return !questions?.some(
          (questionId) =>
            form.values[questionId] === undefined ||
            form.values[questionId] === null ||
            form.values[questionId] === '' ||
            (Array.isArray(form.values[questionId]) && !form.values[questionId]?.length)
        );
      }
      return false;
    },
    [formData, form.values]
  );

  const isDisabled = useMemo(() => {
    const fields = ['farm', 'date'];
    formData?.FarmVisitSections?.forEach((section) => {
      section?.FarmVisitQuestions?.forEach((question) => {
        if (question?.Required) {
          fields?.push(question.id);
        }
      });
    });

    return fields?.some(
      (questionId) =>
        form.values[questionId] === undefined ||
        form.values[questionId] === null ||
        form.values[questionId] === '' ||
        (Array.isArray(form.values[questionId]) && !form.values[questionId]?.length)
    );
  }, [formData, form.values]);

  const getModalText = useCallback(
    (message) => {
      if (form.values) {
        const farmName = farmsList?.find((f) => f.farm_id === form?.values?.farm)?.farm_name || '';
        const date = formatDate(form?.values?.date);

        return `${message} ${farmName}, ${date}`;
      }
      return '';
    },
    [farmsList, form.values]
  );

  return (
    <>
      <form onSubmit={form.onSubmit}>
        <Box
          component='div'
          sx={{ display: { xs: 'block', sm: 'block', md: 'flex' }, justifyContent: 'space-between' }}
        >
          <Box component='div' sx={{ width: { md: '50%', sm: '100%' } }}>
            <Select
              id='farm-select'
              label={t('farm')}
              name='farm'
              onChange={(e) => {
                form.handleChange(e);
                selectFarm(e.target.value);
              }}
              options={farmsList?.map((farm) => ({ label: farm?.farm_name, value: farm?.farm_id }))}
              value={form.values.farm}
            />
          </Box>
        </Box>

        {fetching ? (
          <Box component='div' sx={{ mt: 3 }}>
            <Skeleton rows={8} />
          </Box>
        ) : (
          formData &&
          sortBy(formData?.FarmVisitSections, ['Order'])?.map((section) => (
            <Accordion
              defaultExpanded
              key={section.id}
              sx={{
                background: 'none',
                boxShadow: 'none',
                border: '1px solid #ccc',
                borderRadius: '4px',
                mt: 2,
                '::before': { display: 'none' },
              }}
            >
              <AccordionSummary
                aria-controls={`panel-${section.id}-content`}
                expandIcon={<ExpandMoreIcon />}
                id={`panel-${section.id}`}
              >
                <Box component='div'>
                  <Box component='div' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography component='div' sx={{ fontWeight: 'bold', fontSize: 18 }}>
                      {section.Title}
                    </Typography>
                    {isSectionComplete(section.id) && (
                      <CheckCircleOutlineIcon sx={{ color: (theme) => theme.palette?.primary?.success, ml: 1 }} />
                    )}
                  </Box>
                  <Typography
                    component='div'
                    sx={{ color: (theme) => theme?.palette?.primary?.greyDark, fontSize: 14 }}
                  >
                    {section.Description}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {sortBy(section?.FarmVisitQuestions, ['Order'])?.map((question, index) => (
                  <Paper
                    key={question.id}
                    sx={{ mb: 2, p: 2, borderLeft: (theme) => `4px solid ${theme?.palette?.primary?.main}` }}
                  >
                    <Typography component='div' sx={{ display: 'flex' }}>
                      <Typography component='span' sx={{ fontWeight: 'bold' }}>
                        {`${index + 1}. ${question.Title}`}
                      </Typography>
                      {question.Required && (
                        <Typography
                          component='span'
                          sx={{ color: (theme) => theme?.palette?.primary?.danger, ml: '4px' }}
                        >
                          &#42;
                        </Typography>
                      )}
                    </Typography>
                    <Typography
                      component='div'
                      sx={{ color: (theme) => theme?.palette?.primary?.greyDark, fontSize: 14 }}
                    >
                      {question.Description}
                    </Typography>
                    <Box component='div' sx={{ mt: 1 }}>
                      {renderFormField(question.id, question.Type, question.FarmVisitQuestionOptions)}
                    </Box>
                  </Paper>
                ))}
              </AccordionDetails>
            </Accordion>
          ))
        )}

        {formData && (
          <Box component='div' sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={loading || isDisabled}
              onClick={(e) => {
                e.preventDefault();
                onSubmitClick(form.values);
              }}
              type='submit'
            >
              {t('submit')}
            </Button>
          </Box>
        )}

        <ConfirmationModal
          loading={loading}
          onClose={() => toggleModal(false)}
          onSubmit={handleSubmit}
          open={isModalOpen}
          submitText={t('submit')}
          text={getModalText(t('farmVisit.confirmationModalText'))}
          variant='success'
        />
      </form>
    </>
  );
};

export default FarmVisitForm;
