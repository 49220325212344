import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FarmVisitForm } from '../../../components/farm-visit';
import { Button } from '../../../components/shared';
import routes from '../../../constants/routes';
import { useLoading } from '../../../hooks';
import { farmsSelector } from '../../../reducers/selectors';
import farmVisitService from '../../../services/farmVisitService';
import { containerPadding } from '../../../styles/theme/shared';

const FarmVisitNewPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedFarm, selectFarm] = useState(null);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const { loading, startLoading, stopLoading } = useLoading();

  const farms = useSelector(farmsSelector);
  const { farmsList = [] } = farms;

  const fetchQuestionnaire = async (farmId) => {
    if (farmId) {
      setFetching(true);
      const res = await farmVisitService.getFarmVisitForm(farmId);
      if (res && res?.questionnaire) {
        setQuestionnaire(res?.questionnaire);
      }
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchQuestionnaire(selectedFarm).catch(console.error);
  }, [selectedFarm]);

  const onSubmit = async (formObj) => {
    startLoading();

    try {
      const body = {
        Farm_ID: formObj.farm,
        FarmVisitQuestionnaireId: questionnaire.id,
      };
      const answers = [];

      Object.keys(formObj)?.forEach((key) => {
        if (key !== 'farm' && key !== 'date') {
          answers?.push({ FarmVisitQuestionId: key, Value: formObj[key] });
        }
      });
      body.FarmVisitAnswers = answers;

      await farmVisitService.createNewResult(body);
      setSuccess(true);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  return (
    <Container maxWidth={false} sx={{ p: containerPadding }}>
      <h2>{questionnaire?.Title || t('farmVisit.defaultTitle')}</h2>
      {isSuccess ? (
        <Box component='div' sx={{ mt: 2 }}>
          <MuiAlert severity='success'>
            <AlertTitle sx={{ fontWeight: 'bold' }}>{t('success')}</AlertTitle>
            {`${t('farmVisit.successAlert')}.`}
            <Typography
              component='span'
              onClick={() => navigate(routes.FARM_VISIT_RESULTS)}
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                cursor: 'pointer',
                color: '#1d4620',
                marginLeft: '6px',
              }}
            >
              {`${t('farmVisit.linkMessage')}.`}
            </Typography>
          </MuiAlert>
          <Box component='div' sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              onClick={() => {
                selectFarm(null);
                setQuestionnaire(null);
                setSuccess(false);
              }}
            >
              {t('farmVisit.resetForm')}
            </Button>
          </Box>
        </Box>
      ) : (
        <FarmVisitForm
          farmsList={farmsList}
          fetching={fetching}
          formData={questionnaire}
          loading={loading}
          onSubmit={onSubmit}
          selectFarm={selectFarm}
        />
      )}
    </Container>
  );
};

export default FarmVisitNewPage;
