/* eslint-disable max-len */
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionWrap } from '../../../components/admin-farm-visit';
import LoadScreen from '../../../components/load-screen';
import { Input } from '../../../components/shared';
import { EMPTY_OPTION, EMPTY_QUESTION, EMPTY_SECTION } from '../../../constants/adminFarmVisit';
import { getUUID } from '../../../helpers';
import { formatToForm, formatToRequest } from '../../../helpers/farmVisit';
import { useAlert, useLoading } from '../../../hooks';
import farmVisitService from '../../../services/farmVisitService';
import { containerPadding } from '../../../styles/theme/shared';

const AdminFarmVisit = () => {
  const { t } = useTranslation();

  const [sections, setSections] = useState([EMPTY_SECTION]);
  const [formTitle, setFormTitle] = useState('New Form');

  const { showAlert } = useAlert();
  const { loading, startLoading, stopLoading } = useLoading(false);

  const onLoad = async () => {
    try {
      startLoading();
      const resp = await farmVisitService.getAdminVisit();
      const formattedResp = formatToForm(resp);
      if (formattedResp?.title) {
        setFormTitle(formattedResp?.title);
      }
      if (formattedResp?.sections) {
        setSections(formattedResp?.sections);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  const handleUpdateSection = (section, field, newValue) => {
    const foundIndex = sections.findIndex((q) => q.id === section.id);
    const sectionCopy = [...sections];
    sectionCopy[foundIndex][field] = newValue;
    setSections(sectionCopy);
  };

  const handleUpdateQuestion = (section, question, field, newValue) => {
    const foundIndexSect = sections.findIndex((q) => q.id === section.id);
    const foundIndexQuest = sections[foundIndexSect].questions.findIndex((q) => q.id === question.id);
    const sectionCopy = [...sections];
    if (field === 'type' && !sectionCopy[foundIndexSect]?.questions[foundIndexQuest]?.options?.length) {
      sectionCopy[foundIndexSect].questions[foundIndexQuest].options = [];
      sectionCopy[foundIndexSect].questions[foundIndexQuest].options.push({
        ...EMPTY_OPTION,
        id: getUUID(),
      });
    }
    sectionCopy[foundIndexSect].questions[foundIndexQuest][field] = newValue;
    setSections(sectionCopy);
  };

  const handleUpdateOption = (section, question, index, newValue) => {
    const foundIndexSect = sections.findIndex((q) => q.id === section.id);
    const foundIndexQuest = sections[foundIndexSect].questions.findIndex((q) => q.id === question.id);
    const sectionCopy = [...sections];
    Object.assign(sectionCopy[foundIndexSect].questions[foundIndexQuest].options[index], {
      label: newValue,
      id: getUUID(),
    });
    setSections(sectionCopy);
  };

  const handleOption = (section, question, index, action = 'add') => {
    const foundIndexSect = sections.findIndex((q) => q.id === section.id);
    const foundIndexQuest = sections[foundIndexSect].questions.findIndex((q) => q.id === question.id);
    const sectionCopy = [...sections];
    if (action === 'add') {
      sectionCopy[foundIndexSect].questions[foundIndexQuest].options.push({
        ...EMPTY_OPTION,
        id: getUUID(),
      });
    } else {
      sectionCopy[foundIndexSect].questions[foundIndexQuest].options.splice(index, 1);
    }
    setSections(sectionCopy);
  };

  const handleQuestion = (section, question, action = 'add') => {
    const foundIndexSect = sections.findIndex((q) => q.id === section.id);
    const sectionCopy = [...sections];
    if (action === 'add') {
      sectionCopy[foundIndexSect].questions.push({
        ...EMPTY_QUESTION,
        id: getUUID(),
      });
    } else {
      const foundIndexQuest = sections[foundIndexSect].questions.findIndex((q) => q.id === question.id);
      sectionCopy[foundIndexSect].questions.splice(foundIndexQuest, 1);
    }
    setSections(sectionCopy);
  };

  const handleSection = (section, action = 'add') => {
    const sectionCopy = [...sections];
    if (action === 'add') {
      sectionCopy.push({
        ...EMPTY_SECTION,
        id: getUUID(),
        questions: [
          {
            ...EMPTY_QUESTION,
            id: getUUID(),
          },
        ],
      });
    } else {
      const foundIndexSect = sections.findIndex((q) => q.id === section.id);
      sectionCopy.splice(foundIndexSect, 1);
    }
    setSections(sectionCopy);
  };

  const handleSave = async () => {
    try {
      startLoading();
      const formattedObject = formatToRequest(formTitle, sections);
      await farmVisitService.createNewAdminVisit(formattedObject);
      showAlert('success', t('adminFarmVisit.successAlert'));
    } catch (e) {
      showAlert('error', e?.message);
    } finally {
      stopLoading();
    }
  };

  const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isDisabled =
    !formTitle?.trim()?.length ||
    sections.filter(
      (sect) => !sect?.title?.trim()?.length || sect?.questions?.filter((q) => !q?.title?.trim()?.length)?.length
    ).length;

  return (
    <Container
      maxWidth={false}
      sx={{
        p: {
          ...containerPadding,
          xs: '0',
        },
      }}
    >
      {loading && <LoadScreen />}
      <Box
        component='div'
        sx={{
          p: {
            md: '0',
            sm: '0',
            xs: '0 16px',
          },
        }}
      >
        <h2>{t('adminFarmVisit.title')}</h2>
      </Box>
      <Box
        component='div'
        sx={{
          p: {
            md: '0',
            sm: '0',
            xs: '0 16px',
          },
        }}
      >
        <Input
          error={!formTitle?.length}
          id='admin-farm-visit-form-title'
          label={t('adminFarmVisit.formTitle')}
          name='admin-farm-visit-form-title'
          onChange={(e) => setFormTitle(e.target.value)}
          sx={{
            width: mobileView ? '100%' : '50%',
            mb: 2,
          }}
          value={formTitle}
        />
      </Box>
      <Box
        component='div'
        sx={{
          mb: '16px',
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
          flexDirection: mobileView ? 'column' : 'row',
        }}
      >
        <SectionWrap
          handleOption={handleOption}
          handleQuestion={handleQuestion}
          handleSection={handleSection}
          handleUpdateOption={handleUpdateOption}
          handleUpdateQuestion={handleUpdateQuestion}
          handleUpdateSection={handleUpdateSection}
          mobileView={mobileView}
          sections={sections}
          setSections={setSections}
        />
      </Box>
      <Fab
        aria-label='add'
        color='primary'
        disabled={!!isDisabled}
        onClick={() => handleSave()}
        sx={{
          margin: 0,
          top: 'auto',
          right: 20,
          bottom: 20,
          left: 'auto',
          position: 'fixed',
          fontWeight: 'bold',
        }}
        variant='extended'
      >
        <SaveIcon sx={{ mr: 1 }} />
        {t('submit')}
      </Fab>
    </Container>
  );
};

export default AdminFarmVisit;
